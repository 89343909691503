import React from 'react';
import Footer from '../Footer';
import Header from '../Header';

type LayoutProps = {
	children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
	return (
		<>
			<Header />
			<main
				style={{
					flexGrow: 1,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{children}
			</main>
			<Footer />
		</>
	);
};

export default Layout;
