import { Modal } from 'antd';
import { useEffect } from 'react';

function useErrorModal(error: string | Error) {
	const errorMessage = (error instanceof Error ? error.message : error).trim();

	useEffect(() => {
		if (errorMessage) {
			Modal.error({
				title: 'This is an error message',
				content: errorMessage,
			});
		}
	}, [errorMessage]);
}

export default useErrorModal;
