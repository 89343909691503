import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';

declare module 'axios' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface, no-shadow
	interface AxiosResponse<T> extends Promise<T> {}
}

abstract class HttpClient {
	protected readonly instance: AxiosInstance;

	protected constructor(baseURL: string) {
		this.instance = axios.create({
			baseURL,
			transformResponse: (data) => {
				if (data) {
					return camelizeKeys(JSON.parse(data), (key, convert) =>
						/^[A-Z0-9_]+$/.test(key) ? key : convert(key)
					);
				}
				return data;
			},
		});

		this.initializeResponseInterceptor();
	}

	private initializeResponseInterceptor = () => {
		this.instance?.interceptors.response.use(
			(response: AxiosResponse) => {
				return response;
			},
			(error) => {
				console.log('error', error);
				return Promise.reject(error);
			}
		);
	};
}

export default HttpClient;
