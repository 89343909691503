import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import ArticleDetailPage from 'page/ArticleDetailPage';
import { Helmet } from 'react-helmet-async';
import Layout from './components/Layout';
import MarketPage from './page/MarketPage';
import ResearchPage from './page/ResearchPage';
import TagPage from 'page/TagPage';
import routes from './config/routes';
import { useAppDispatch } from './redux/store';

const AppRouter = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch({
			type: 'LOAD_APP',
		});
	}, [dispatch]);

	return (
		<>
			<Helmet>
				<title>VegaX Terminal</title>
			</Helmet>
			<Layout>
				<Routes>
					{/* <Route path={routes.dashboard.path} element={<DashboardPage />} /> */}
					<Route path={routes.market.path} element={<MarketPage />} />
					<Route path={routes.research.path} element={<ResearchPage />} />
					<Route
						path={`${routes.research.path}/:id`}
						element={<ArticleDetailPage />}
					/>
					<Route
						path={`${routes.tagResearch.path}/:id`}
						element={<TagPage />}
					/>
				</Routes>
			</Layout>
		</>
	);
};

export default AppRouter;
