import { createGlobalStyle } from 'styled-components';
import colors from './colors';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
   
  }
  
  #root {   
      display: flex;   
      flex-direction: column;   
      min-height: 100%; 
     
    } 

  html {
    height: 100%;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
 
  }

body {
    background: ${colors.primary_bg};
    font-family: 'Montserrat';
    font-size:'15px';
    font-weight: 400;

      
}

main{
   background: ${colors.primary_bg};
 

}

footer{
   background: ${colors.primary_bg};
}


`;

export default GlobalStyle;
