import './theme/main.less';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { worker } from './mocks/worker';

if (process.env.NODE_ENV === 'development') {
	worker.stop();
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<BrowserRouter>
		<HelmetProvider>
			<App />
		</HelmetProvider>
	</BrowserRouter>
);
