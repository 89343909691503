export const marketIndexResponseData = [
	{
		name: 'VDBX',
		fullname: 'VegaX Digital Blue Index™',
		category: 'Market Composite',
		value: 273.7418276148884,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [
			['btc', 'bitcoin'],
			['eth', 'ethereum'],
			['bnb', 'binancecoin'],
			['ada', 'cardano'],
			['xrp', 'ripple'],
		],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vdbx_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vdbx_30m.png',
		},
		percentage_change: {
			'24h': -0.14596163344606014,
			'7d': 13.969390892443906,
			'30d': -9.021328706080046,
			'3m': 17.39952976441054,
			ytd: -54.80705395325371,
			'1y': -52.13596583147405,
		},
		asset_info: {
			ada: {
				market_cap: '17203617072.332469940186',
				weight: '0.04146207988690569744937928162',
				reference_price: 0.5012,
				percent_change_1d: -0.0169,
				percent_change_7d: 0.0056,
			},
			bnb: {
				market_cap: '48115913412.024482727051',
				weight: '0.1159631627077546782831236719',
				reference_price: 294.1,
				percent_change_1d: -0.0041,
				percent_change_7d: 0.0621,
			},
			btc: {
				market_cap: '416073628622.094848632813',
				weight: '0.4',
				reference_price: 22395.74,
				percent_change_1d: 0.0261,
				percent_change_7d: 0.1313,
			},
			eth: {
				market_cap: '211895058510.347595214844',
				weight: '0.4000000000000000000000000000',
				reference_price: 1716.37,
				percent_change_1d: -0.0282,
				percent_change_7d: 0.0609,
			},
			xrp: {
				market_cap: '17665293814.173290252686',
				weight: '0.04257475740533962426749704644',
				reference_price: 0.3598,
				percent_change_1d: 0.013,
				percent_change_7d: 0.0808,
			},
		},
	},
	{
		name: 'VNFTX',
		fullname: 'VegaX Non-Fungible Token Index',
		category: 'Sector',
		value: 296.9682906792165,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [
			['axs', 'axie-infinity'],
			['flow', 'flow'],
			['mana', 'decentraland'],
			['sand', 'the-sandbox'],
			['theta', 'theta-token'],
		],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vnftx_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vnftx_30m.png',
		},
		percentage_change: {
			'24h': -0.9796134921100033,
			'7d': 11.623236831276477,
			'30d': -25.97391776202843,
			'3m': -2.0370491271132787,
			ytd: -82.18156627894797,
			'1y': -66.68103298807483,
		},
		asset_info: {
			axs: {
				market_cap: '1306977634.604902267456',
				weight: '0.1722879857620003931919478261',
				reference_price: 14.24,
				percent_change_1d: -0.0213,
				percent_change_7d: -0.0042,
			},
			flow: {
				market_cap: '2188143484.193581581116',
				weight: '0.2884447472308289203851795509',
				reference_price: 2.103,
				percent_change_1d: -0.0061,
				percent_change_7d: 0.0959,
			},
			mana: {
				market_cap: '1454758023.729160785675',
				weight: '0.1917686447290835548928279166',
				reference_price: 0.7944,
				percent_change_1d: -0.0114,
				percent_change_7d: 0.0057,
			},
			sand: {
				market_cap: '1459157009.523275375366',
				weight: '0.1923485264208561661076238073',
				reference_price: 0.9592,
				percent_change_1d: -0.016,
				percent_change_7d: 0.0019,
			},
			theta: {
				market_cap: '1176969504.840145111084',
				weight: '0.1551500958572309654224208991',
				reference_price: 1.187,
				percent_change_1d: 0.0059,
				percent_change_7d: 0.0094,
			},
		},
	},
	{
		name: 'VDCX',
		fullname: 'VegaX Defi Core Index',
		category: 'Sector',
		value: 82.9670784488774,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [
			['uni', 'uniswap'],
			['aave', 'aave'],
			['snx', 'havven'],
			['mkr', 'maker'],
			['cake', 'pancakeswap-token'],
		],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vdcx_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vdcx_30m.png',
		},
		percentage_change: {
			'24h': 0.1030339635322479,
			'7d': 9.739952335254944,
			'30d': -23.60176588514225,
			'3m': 28.97951519072237,
			ytd: -88.71156541231619,
			'1y': -86.57447290380998,
		},
		asset_info: {
			aave: {
				market_cap: '1286969221.077701568604',
				weight: '0.2333924447723556553182388913',
				reference_price: 90.9,
				percent_change_1d: -0.0076,
				percent_change_7d: -0.0022,
			},
			cake: {
				market_cap: '640993511.382233858109',
				weight: '0.1162444604381754402068960800',
				reference_price: 4.414,
				percent_change_1d: -0.0284,
				percent_change_7d: 0.0641,
			},
			mkr: {
				market_cap: '677267227.649457216263',
				weight: '0.1228227151329509929187257436',
				reference_price: 737,
				percent_change_1d: -0.0238,
				percent_change_7d: -0.0067,
			},
			snx: {
				market_cap: '703281304.682339668274',
				weight: '0.1275403796565179115561392849',
				reference_price: 2.942,
				percent_change_1d: -0.0064,
				percent_change_7d: -0.021,
			},
			uni: {
				market_cap: '4886424649.044754028320',
				weight: '0.4',
				reference_price: 6.65,
				percent_change_1d: 0.0247,
				percent_change_7d: 0.0106,
			},
		},
	},
	{
		name: 'VSOLX',
		fullname: 'VegaX Solana Index',
		category: 'Sector',
		value: 107.10809089458769,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [
			['sol', 'solana'],
			['srm', 'serum'],
			['audio', 'audius'],
		],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vsolx_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vsolx_30m.png',
		},
		percentage_change: {
			'24h': 4.561568797334142,
			'7d': 17.661722699428545,
			'30d': -17.54670896930831,
			'3m': 1.0732645467618054,
			ytd: -78.7491580387212,
			'1y': -84.13395639106203,
		},
		asset_info: {
			audio: {
				market_cap: '233835245.456453442574',
				weight: '0.2115237358856544773527955510',
				reference_price: 0.293142075316,
				percent_change_1d: 0.0333,
				percent_change_7d: 0.0361,
			},
			sol: {
				market_cap: '12331013775.496049880981',
				weight: '0.5',
				reference_price: 37.344616214599,
				percent_change_1d: 0.0699,
				percent_change_7d: 0.1592,
			},
			srm: {
				market_cap: '318904721.236599206924',
				weight: '0.2884762641143455226472044490',
				reference_price: 0.86700094491,
				percent_change_1d: 0.0125,
				percent_change_7d: 0.0737,
			},
		},
	},
	{
		name: 'VSCPX',
		fullname: 'VegaX Smart Contract Index™',
		category: 'Sector',
		value: 958.4663068443908,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vscpx_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vscpx_30m.png',
		},
		percentage_change: {
			'24h': 0.3145716949568387,
			'7d': 12.648098404171343,
			'30d': -12.11234166589437,
			'3m': 11.337874433645853,
			ytd: -63.236100021939045,
			'1y': -63.97877925777982,
		},
		asset_info: {
			eth: {
				market_cap: '211895058510.347595214844',
				weight: 0.2,
				reference_price: 1716.37,
				percent_change_1d: -0.0282,
				percent_change_7d: 0.0609,
			},
			ada: {
				market_cap: '17203617072.332469940186',
				weight: 0.2,
				reference_price: 0.5012,
				percent_change_1d: -0.0169,
				percent_change_7d: 0.0056,
			},
			dot: {
				market_cap: '8842559955.013814926147',
				weight: 0.2,
				reference_price: 7.66,
				percent_change_1d: -0.0052,
				percent_change_7d: 0.0408,
			},
			sol: {
				market_cap: '12331013775.496049880981',
				weight: 0.2,
				reference_price: 37.44,
				percent_change_1d: 0.07,
				percent_change_7d: 0.1635,
			},
			bnb: {
				market_cap: '48115913412.024482727051',
				weight: 0.2,
				reference_price: 294.1,
				percent_change_1d: -0.0041,
				percent_change_7d: 0.0621,
			},
		},
	},
	{
		name: 'VBXM',
		fullname: 'VegaX Bitcoin BuyWrite Index™',
		category: 'Option Strategy',
		value: 339.5171157330524,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [['btc', 'bitcoin']],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vbxm_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vbxm_30m.png',
		},
		percentage_change: {
			'24h': 2.262305457265319,
			'7d': 16.315458817062137,
			'30d': -7.726105241917549,
			'3m': 5.8690986005205765,
			ytd: -47.563315115578156,
			'1y': -47.42694771199752,
		},
		asset_info: {
			prev_month_last_perp_adjusted_close: 20147.5,
			ideal_strike: 24177,
			maturity: '2022-09-30T00:00:00Z',
			strike: 24000,
			cur_month_frist_option_premium: 0.01625,
			estimated_asset_price_at_maturity: 22229.75,
			mark_to_market: 0,
			'!mark_to_market':
				'((22229.75 - 24177.0) / 24177.0) * 302.96636771052215',
			net_position: 307.8895711858181,
			'!net_position calculation (starting position factored out)':
				'(302.96636771052215 + 4.923203475295985 - 0)',
			price_adjusted_val: 339.51711573305465,
			market_cap: '416073628622.094848632813',
			weight: 1,
			reference_price: 22339.67050262336,
			percent_change_1d: 0.0276,
			percent_change_7d: 0.1291,
			'!index_calculation':
				'(339.51711573305465 / 332.00612309189177) * 332.00612309188955',
			prev_month_last_index: 302.96636771052215,
			current_asset_price: 22217.125,
			prev_month_last_asset_price: 20147.5,
			current_asset_price_relative_to_the_prev_month_last: 1.102723662985482,
		},
	},
	{
		name: 'VEXM',
		fullname: 'VegaX Ethereum BuyWrite Index™',
		category: 'Option Strategy',
		value: 150.37572536220884,
		target_date: '2022-09-12T00:00:00Z',
		target_datetime: '2022-09-12T23:59:59Z',
		target_assets: [['eth', 'ethereum']],
		trends: {
			'7d': 'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vexm_7d.png',
			'30m':
				'https://vegax-terminal-static-test-1.s3.amazonaws.com/static/images/trends/vexm_30m.png',
		},
		percentage_change: {
			'24h': -1.9088009433895263,
			'7d': 8.640440597954354,
			'30d': -7.7807099812996565,
			'3m': 10.803966929584028,
			ytd: -61.480749272717325,
			'1y': -58.17709755363857,
		},
		asset_info: {
			prev_month_last_perp_adjusted_close: 1570.1125,
			ideal_strike: 1884.1349999999998,
			maturity: '2022-09-30T00:00:00Z',
			strike: 1900,
			cur_month_frist_option_premium: 0.042875,
			estimated_asset_price_at_maturity: 1710.7875,
			mark_to_market: 0,
			'!mark_to_market':
				'((1710.7875 - 1884.1349999999998) / 1884.1349999999998) * 131.16838397399707',
			net_position: 136.7922284368822,
			'!net_position calculation (starting position factored out)':
				'(131.16838397399707 + 5.623844462885125 - 0)',
			price_adjusted_val: 150.37572536220787,
			market_cap: '211895058510.347595214844',
			weight: 1,
			reference_price: 1710.164290851521,
			percent_change_1d: -0.029,
			percent_change_7d: 0.057,
			'!index_calculation':
				'(150.37572536220787 / 153.3019545162486) * 153.3019545162496',
			prev_month_last_index: 131.16838397399707,
			current_asset_price: 1726.025,
			prev_month_last_asset_price: 1570.1125,
			current_asset_price_relative_to_the_prev_month_last: 1.0993002093799011,
		},
	},
];
