import colors from 'theme/colors';
import device from 'utils/devices';
import styled from 'styled-components';

export const Footer = styled.footer`
	border-top: 1px solid #006ca8;
	width: 100%;
	display: flex;
	justify-content: center;
	flex: 0 0 auto;
`;

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 1440px;
	justify-content: space-around;
	padding: 24px 30px;

	@media ${device.mobile} {
		padding: 10px 0 0 0;
	}
`;

export const CategorysWrapper = styled.div`
	display: flex;
`;

export const CategoryWrapper = styled.div`
	padding-right: 40px;
	font-size: 10px;
	justify-content: first baseline;

	h4 {
		font-size: 12px;
		font-weight: 600;
		padding-left: 40px;
		color: ${colors.dark_navy_text_2};

		@media ${device.mobile} {
			font-size: 11px;
			padding-left: 20px;
		}
	}

	a {
		color: ${colors.dark_gray_text};
		li {
			list-style: none;
			@media ${device.mobile} {
				padding-left: 0px;
			}
		}

		:hover {
			font-weight: 800px;
			color: ${colors.blue_text};
		}
	}

	@media ${device.mobile} {
		padding-right: 0px;
		font-size: 10px;
	}
`;

export const InputWrapper = styled.div`
	padding: 0 20px;
	h4 {
		font-size: 13px;
		font-weight: 600;
		color: ${colors.dark_navy_text_2};
	}

	@media ${device.laptop} {
		padding-top: 30px;
	}
`;

export const InputContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
`;

export const Input = styled.input`
	width: 300px;
	padding: 5px 10px;
	border: 0.141555px solid #cdd2e3;
	box-shadow: 0.707775px 2.12332px 2.8311px rgba(41, 65, 152, 0.1);
	border-radius: 2.8311px;

	::placeholder {
		font-size: 13px;
		color: #77838f;
		font-weight: 400;
	}
`;

export const SubscribeButton = styled.button`
	position: absolute;
	border: none;
	height: 28px;
	right: 5px;
	top: 3px;
	padding: 2px 20px;
	background: #072c4a;
	box-shadow: 0.707775px 0.707775px 2.8311px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	color: ${colors.white_text};
	font-weight: 700;
	font-size: 11px;
	cursor: pointer;
`;

export const Banner = styled.img`
	width: 430px;
	cursor: pointer;

	@media ${device.laptop} {
		padding-top: 50px;
	}

	@media ${device.mobile} {
		width: 100%;
	}
`;
