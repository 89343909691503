import React from 'react';

import { getMarketIndexIcons } from 'utils/indexIcon';
import {
	MarketIndexWrapper,
	MarketIndexSymbol,
	MarketIndexFullName,
} from './MarketIndexTable.styled';

interface TableIndexProps {
	fullName: string;
	symbol: string;
}

function TableIndex({ symbol, fullName }: TableIndexProps) {
	const { image, alt } = getMarketIndexIcons(symbol);

	return (
		<MarketIndexWrapper>
			<div>
				<img width="18px" height="18px" src={image} alt={alt} />
			</div>
			<div>
				<MarketIndexSymbol>{symbol}</MarketIndexSymbol>
				<MarketIndexFullName>{fullName}</MarketIndexFullName>
			</div>
		</MarketIndexWrapper>
	);
}

export default TableIndex;
