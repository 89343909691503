import React from 'react';

import MarketIndexCustomize from './MarketIndexCustomize';
import {
	MarketIndexHeaderTitle,
	MarketIndexHeaderWrapper,
} from './MarketIndexHeader.styled';

const MarketIndexHeader = () => {
	return (
		<MarketIndexHeaderWrapper>
			<MarketIndexHeaderTitle>Market Index</MarketIndexHeaderTitle>
			<MarketIndexCustomize />
		</MarketIndexHeaderWrapper>
	);
};

export default MarketIndexHeader;
