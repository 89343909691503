// type routeKey = 'dashboard' | 'market' | 'research' | 'tagResearch';
type routeKey = 'market' | 'research' | 'tagResearch';
type Route = {
	path: string;
	name: string;
};

type Routes = {
	[k in routeKey]: Route;
};

export const routes: Routes = {
	// dashboard: {
	// 	path: '/',
	// 	name: 'Dashboard',
	// },
	market: {
		path: '/market',
		name: 'Market Index',
	},
	research: {
		path: '/',
		name: 'Research',
	},
	tagResearch: {
		path: '/tag',
		name: 'Tag',
	},
};

export default routes;
