import { rest } from 'msw';
import { marketIndexResponseData } from './data';

export const handlers = [
	// Market Index API : /api/v1//indexes
	rest.get(
		'https://api-terminal-test.vegaxh.com/api/v1/indexes',
		(req, res, ctx) => {
			return res(ctx.status(200), ctx.json(marketIndexResponseData));
		}
	),
];
