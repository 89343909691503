import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';

import MarketIndexContents from './components/MarketIndexContents';
import MarketIndexHeader from './components/MarketIndexHeader';
import { MarketIndexHeaderDivider } from './components/MarketIndexHeader/MarketIndexHeader.styled';

const MarketPageWrapper = styled.div`
	max-width: 1200px;
	width: 100%;
	padding: 42px 21px;
	background-color: ${colors.primary_bg};
`;

const MarketPage = () => {
	return (
		<MarketPageWrapper>
			<MarketIndexHeader />
			<MarketIndexHeaderDivider />
			<MarketIndexContents />
		</MarketPageWrapper>
	);
};

export default MarketPage;
