import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import mainApi from 'services/Api';
import { marketIndexActions } from './slice';

function* fetchIndexes() {
	try {
		yield put(marketIndexActions.fetchIndexes());
		const { data } = yield call(mainApi.fetchIndexes);
		yield put(marketIndexActions.fetchIndexesSuccess(data));
	} catch (error) {
		if (error instanceof Error) {
			yield put(marketIndexActions.fetchIndexesFailure(error.message));
		}
		console.error(error);
	}
}

function* watchFetchIndexes() {
	yield takeLatest(['LOAD_APP'], fetchIndexes);
}

export default function* MarketIndexesSaga() {
	yield all([fork(watchFetchIndexes)]);
}
