import React from 'react';

import settingsIcon from 'assets/icons/settings.svg';
import { CustomButton, CustomizeButtonText } from './MarketIndexHeader.styled';

const MarketIndexCustomize = () => {
	return (
		<div>
			<CustomButton type="button">
				<img alt="setting icon" src={settingsIcon} />
				<CustomizeButtonText>Customize</CustomizeButtonText>
			</CustomButton>
		</div>
	);
};

export default MarketIndexCustomize;
