import * as Styled from './DesktopHeader.styled';

import { HeaderProps, navigation } from '../Header';

import React from 'react';
import bluesearch from 'assets/icons/bluesearch.svg';
import logo from 'assets/icons/logo.svg';
import profile from 'assets/icons/profile.svg';
import routes from 'config/routes';

const DesktopHeader = ({ navs }: HeaderProps) => {
	return (
		<Styled.Header>
			<Styled.Container>
				<Styled.Link href={routes.research.path}>
					<Styled.Logo src={logo} alt="logo" />
					<span>TERMINAL</span>
				</Styled.Link>
				<Styled.NavWrapper>
					<Styled.NavList>
						{navs.map((nav: navigation) => (
							<Styled.NavItem href={nav.link} key={nav.name}>
								{nav.name}
							</Styled.NavItem>
						))}
					</Styled.NavList>
				</Styled.NavWrapper>
				<Styled.ButtonInputWrapper>
					<Styled.InputWrapper>
						<Styled.Input placeholder="Search" />
						<Styled.SearchButton src={bluesearch} alt="search" />
					</Styled.InputWrapper>
					<Styled.Btn>Go Pro</Styled.Btn>
					<Styled.Profile src={profile} alt="profile" />
				</Styled.ButtonInputWrapper>
			</Styled.Container>
		</Styled.Header>
	);
};

export default DesktopHeader;
