const colors = {
	primary_bg: '#F2F9FE',
	navbar_bg: '#FFFFFF',
	section_head_dark_bg: '#02588B',
	section_head_light_bg: '#729EC5',
	section_tooltip_dark_bg: '#39C6F3',
	section_tooltip_light_bg: '#FFFFFF',
	input_bg: '#E3F1FC',
	button_bg: '#04A1D3',
	button_hover_bg: '#39C6F3',
	tag_bg: '#e3f1fc',
	tag_hover_bg: '#b7dbf7',
	filter_bg: '#f8f8f8',
	filter_hover_bg: '#009fe4',
	report_filter_bg: '#FFFFFF',
	report_filter_hover_bg: '#0A466F',
	black_text: '#141414',
	blue_text: '#39C6F3',
	dark_gray_text: '#525252',
	dark_gray_text_2: '#053D62',
	dark_navy_text_1: '#072C4A',
	dark_navy_text_2: '#02588B',
	white_text: '#FFFFFF',

	// Primary Colors
	main_dark_navy: '#072C4A',
	dark_navy_2: '#054067',
	dark_navy_3: '#02588B',
	dark_navy_4: '#006CA8',

	main_cyan: '#39C6F3',
	cyan_2: '#04A1D3',
	cyan_3: '#3CD8F8',
	cyan_4: '#3FEAFF',

	black: '#000000',
	dark_grey: '#525252',
	white: '#ffffff',

	// Secondary Colors
	pale_blue_1: '#729EC5',
	pale_blue_2: '#94B7D6',
	pale_blue_3: '#B5CFE6',
	pale_blue_4: '#D0E4F8',
	pale_blue_5: '#E3F1FC',

	aqua_1: '#36C2DA',
	aqua_2: '#94E5F7',
	aqua_3: '#D0EFF7',
	aqua_4: '#ECF7FA',

	// Tertiary Colors
	grey_1: '#999999',
	grey_2: '#BCBEC0',
	grey_3: '#D9DADB',
	grey_4: '#F4F4F4',

	mint_1: '#0CCDA2',
	mint_2: '#17EAAC',
	mint_3: '#B9F9DC',
	mint_4: '#D7FFEE',

	red_1: '#E81350',
	red_2: '#FF4893',
	red_3: '#F2577C',
	red_4: '#F779A0',

	yellow_1: '#FFA624',
	yellow_2: '#FABB17',
	yellow_3: '#F9CD43',
	yellow_4: '#FEE859',
};

export default colors;
