import { ApplicationState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

export const selectArticleDomain = (state: ApplicationState) =>
	state.article || initialState;

export const makeSelectArticles = () =>
	createSelector(selectArticleDomain, (state) => state.article?.results);

export const makeSelectRelatedArticle = () =>
	createSelector(selectArticleDomain, (state) => state.relatedArticle);
