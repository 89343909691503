import * as Styled from './ArticleDetailPage.styled';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { Skeleton, Spin } from 'antd';
import { makeSelectArticles, makeSelectRelatedArticle } from './selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { ArticleResult } from './types';
import MobileNewsCard from 'components/NewsCard/MobileNewsCard/MobileNewsCard';
import ReactHtmlParser from 'react-html-parser';
import ScrollToTop from 'react-scroll-to-top';
import { Tag } from 'components/NewsCard/MobileNewsCard/MobileNewsCard.styled';
import backarrow from 'assets/icons/back-arrow.svg';
import device from 'utils/devices';
import { fetchRelatedArticles } from './slice';
import { nanoid } from 'nanoid';
import noarticle from 'assets/icons/noarticle.svg';
import parse from 'node-html-parser';
import routes from 'config/routes';
import { socialMedia } from './socialMedia';
import useMediaQuery from '../../utils/hooks/useMedia';

const ArticleDetailPage = () => {
	const reg1 = /%../g;
	const reg2 = /\W/g;
	const isTablet = useMediaQuery(`${device.tablet}`);
	const location = useLocation();
	const navigate = useNavigate();
	const { id: ID } = useParams<{ id: string | undefined }>();
	const dispatch = useAppDispatch();
	const allArticles = useAppSelector(makeSelectArticles());
	const relatedArticles = useAppSelector(makeSelectRelatedArticle());

	const article =
		allArticles && allArticles.find(({ id }) => id === parseInt(ID!, 10));

	const publishedDate = article && new Date(article.lastPublishedAt);

	useEffect(() => {
		dispatch(fetchRelatedArticles(article?.id));
	}, [article?.id, dispatch]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [relatedArticles]);

	const htmlFromCMS: any = article?.content.map((item) => {
		let result = '';
		if (item.type === 'paragraph') {
			result += item.value;
		} else if (item.type === 'image') {
			result += `<div style="display:flex; flex-direction:column; justify-content:center; "><img src='${item.value.image.url}' alt="image"/>`;
			result += `<div style="display:flex; justify-content:center;">`;
			result += `${item.value.caption ?? ''}`;
			result += `</div>`;
			result += `</div>`;
		}
		return result;
	});

	const subTitleArray: any = article?.content.map((item) => {
		const subTitleElement = [];

		if (item.type === 'paragraph') {
			subTitleElement.push(
				parse(item.value)
					.querySelectorAll('b')
					.map((bTag) => bTag.text)
			);
		}

		return subTitleElement.flat(Infinity);
	});

	const flattenArray = (array: string[][]) => {
		return array.flat();
	};

	const ref = useRef<HTMLInputElement | null>(null);
	useEffect(() => {
		if (ref && ref.current) {
			const bList = ref.current.querySelectorAll('b');
			if (subTitleArray) {
				subTitleArray[0].forEach((title: string, idx: number) => {
					bList[idx].id = `${title}${idx}`;
				});
			}
		}
	}, [article]);

	return (
		<div ref={ref}>
			{isTablet ? (
				<Styled.Wrapper>
					<Styled.BackButton onClick={() => navigate(-1)}>
						<Styled.BackImagewrapper>
							<img src={backarrow} alt="back_arrow" />
							<span>Back to All Research</span>
						</Styled.BackImagewrapper>
					</Styled.BackButton>
					<Styled.ArticleContainer>
						{article ? (
							<Styled.ArticleWrapper>
								<Styled.SubCategoryText>
									{article?.subcategory.name}
								</Styled.SubCategoryText>
								<Styled.Title>{article?.header}</Styled.Title>
								<Styled.DetailTextWrapper>
									<Styled.DetailText>{`${
										publishedDate?.toDateString().split(' ')[2]
									}
				 ${publishedDate?.toDateString().split(' ')[1]}
				${publishedDate?.toDateString().split(' ')[3]}
				`}</Styled.DetailText>
									<Styled.DetailText left>
										{article?.readTime}
									</Styled.DetailText>
								</Styled.DetailTextWrapper>

								<Styled.BannerImage>
									<img
										src={article?.banner.image.url}
										alt="banner"
										width="100%"
									/>
								</Styled.BannerImage>
								<Styled.BannerText>
									{article?.banner.caption
										? ReactHtmlParser(article?.banner.caption)
										: null}
								</Styled.BannerText>
								<div className="article-content">
									<Styled.ContentWrapper>
										{htmlFromCMS && ReactHtmlParser(htmlFromCMS)}
									</Styled.ContentWrapper>
								</div>

								<Styled.ArticleFooterContainer>
									<Styled.TagsWrapper>
										{article?.tags?.map((tag) => (
											<Link to={`/tag/${tag.name}`} key={nanoid()}>
												<Tag key={nanoid()}>{tag.name}</Tag>
											</Link>
										))}
									</Styled.TagsWrapper>
									<Styled.SocialMediaContainer>
										<span>Share to:</span>
										<Styled.SocialMediaLinksWrapper>
											{socialMedia.map((item) => (
												<a href={item.link}>
													<img src={item.image} alt={item.alt} />
												</a>
											))}
										</Styled.SocialMediaLinksWrapper>
									</Styled.SocialMediaContainer>
								</Styled.ArticleFooterContainer>
							</Styled.ArticleWrapper>
						) : (
							<Skeleton active paragraph={{ rows: 20 }} />
						)}
					</Styled.ArticleContainer>
					<Styled.RelatedContentText>Related Content</Styled.RelatedContentText>
					<Styled.RelatedArticleWrapper>
						{relatedArticles.count > 0 ? (
							relatedArticles.results
								.slice(0, 4)
								.map((relatedArticle: ArticleResult) => (
									<MobileNewsCard related news={relatedArticle} />
								))
						) : (
							<Styled.NoArticleWrapper>
								<Styled.NoArticleContainer src={noarticle} alt="noarticle" />
							</Styled.NoArticleWrapper>
						)}
					</Styled.RelatedArticleWrapper>
					<Styled.ExploreButtonWrapper>
						<Styled.ExploreButton onClick={() => navigate(-1)}>
							EXPLORE ALL
						</Styled.ExploreButton>
					</Styled.ExploreButtonWrapper>
					<ScrollToTop
						smooth
						color="#02588B"
						top={100}
						height="20px"
						width="28px"
					/>
				</Styled.Wrapper>
			) : (
				<Styled.Wrapper>
					<Styled.TopSection>
						<Styled.TitleContainer>
							<Styled.BackButton onClick={() => navigate(-1)}>
								<Styled.BackImagewrapper>
									<img src={backarrow} alt="back_arrow" />
									<span>Back to All Research</span>
								</Styled.BackImagewrapper>
							</Styled.BackButton>
							<Styled.Text>Contents</Styled.Text>
							<Styled.Navigation>
								{subTitleArray ? (
									flattenArray(subTitleArray).map(
										(subTitle: string, idx: number) => (
											<Styled.Link
												active={location.hash
													.toLowerCase()
													.replaceAll(reg1, '')
													.includes(
														subTitle.toLowerCase().replaceAll(reg2, '')
													)}
												key={`${subTitle}${idx}`}
											>
												<a href={`#${subTitle}${idx}`}>{subTitle}</a>
											</Styled.Link>
										)
									)
								) : (
									<Spin
										size="small"
										style={{
											textAlign: 'center',
											marginTop: '20px',
											marginLeft: '10px',
										}}
									/>
								)}
							</Styled.Navigation>
						</Styled.TitleContainer>
						<Styled.ArticleContainer>
							{article ? (
								<Styled.ArticleWrapper>
									<Styled.SubCategoryText>
										{article?.subcategory.name}
									</Styled.SubCategoryText>
									<Styled.Title>{article?.header}</Styled.Title>
									<Styled.DetailTextWrapper>
										<Styled.DetailText>{`${
											publishedDate?.toDateString().split(' ')[2]
										}
						 	${publishedDate?.toDateString().split(' ')[1]}
							${publishedDate?.toDateString().split(' ')[3]}
							`}</Styled.DetailText>
										<Styled.DetailText left>
											{article?.readTime}
										</Styled.DetailText>
									</Styled.DetailTextWrapper>

									<Styled.BannerImage>
										<img
											src={article?.banner.image.url}
											alt="banner"
											width="100%"
										/>
									</Styled.BannerImage>
									<Styled.BannerText>
										{article?.banner.caption
											? ReactHtmlParser(article?.banner.caption)
											: null}
									</Styled.BannerText>
									<Styled.ContentWrapper>
										{htmlFromCMS && ReactHtmlParser(htmlFromCMS)}
									</Styled.ContentWrapper>
									<Styled.ArticleFooterContainer>
										<Styled.TagsWrapper>
											{article?.tags?.map((tag) => (
												<Link to={`/tag/${tag.name}`} key={nanoid()}>
													<Tag key={nanoid()}>{tag.name}</Tag>
												</Link>
											))}
										</Styled.TagsWrapper>
										<Styled.SocialMediaContainer>
											<span>Share to:</span>
											<Styled.SocialMediaLinksWrapper>
												{socialMedia.map((item) => (
													<a href={item.link}>
														<img src={item.image} alt={item.alt} />
													</a>
												))}
											</Styled.SocialMediaLinksWrapper>
										</Styled.SocialMediaContainer>
									</Styled.ArticleFooterContainer>
								</Styled.ArticleWrapper>
							) : (
								<Skeleton active paragraph={{ rows: 20 }} />
							)}
						</Styled.ArticleContainer>
					</Styled.TopSection>
					<Styled.RelatedContentSection>
						<Styled.RelatedContentWrapper>
							<Styled.RelatedContentText>
								Related Content
							</Styled.RelatedContentText>
							<Styled.ExploreButton onClick={() => navigate(-1)}>
								EXPLORE ALL
							</Styled.ExploreButton>
						</Styled.RelatedContentWrapper>
						<Styled.RelatedArticleWrapper>
							{relatedArticles.count > 0 ? (
								relatedArticles.results
									.slice(0, 4)
									.map((relatedArticle: ArticleResult) => (
										<MobileNewsCard related news={relatedArticle} />
									))
							) : (
								<Styled.NoArticleWrapper>
									<Styled.NoArticleContainer src={noarticle} alt="noarticle" />
								</Styled.NoArticleWrapper>
							)}
						</Styled.RelatedArticleWrapper>
					</Styled.RelatedContentSection>
					<ScrollToTop
						smooth
						color="#02588B"
						top={100}
						height="20px"
						width="28px"
					/>
				</Styled.Wrapper>
			)}
		</div>
	);
};

export default ArticleDetailPage;
