import styled from 'styled-components';

import colors from 'theme/colors';

// TODO: When making customButton, remove display none
export const CustomButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	display: none;
	padding: 0;
	line-height: 0;
`;

export const CustomizeButtonText = styled.span`
	margin-left: 6px;
	font-weight: 400;
	font-size: 12px;
`;

export const MarketIndexHeaderTitle = styled.h5`
	font-weight: 600;
	font-size: 24px;
	margin: 0;
`;

export const MarketIndexHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MarketIndexHeaderDivider = styled.hr`
	border: none;
	border-top: 0.5px solid ${colors.pale_blue_3};
	margin-top: 15px;
`;
