import {
	Effect,
	SagaReturnType,
	all,
	call,
	fork,
	put,
	takeLatest,
} from 'redux-saga/effects';
import {
	fetchArticlesSuccess,
	fetchRelatedArticles as fetchRelatedArticlesAction,
	fetchRelatedArticlesSuccess,
} from './slice';
import mainApi from '../../services/Api';

function* fetchArticles() {
	try {
		const { data }: SagaReturnType<typeof mainApi.fetchArticles> = yield call(
			mainApi.fetchArticles
		);
		yield put(fetchArticlesSuccess(data));
	} catch (e) {
		console.error(e);
	}
}

function* fetchRelatedArticles(action: Effect) {
	try {
		const { data }: SagaReturnType<typeof mainApi.fetchRelatedArticles> =
			yield call(mainApi.fetchRelatedArticles, action.payload);

		yield put(fetchRelatedArticlesSuccess(data));
	} catch (e) {
		console.error(e);
	}
}

function* watchFetchArticles() {
	yield takeLatest(['LOAD_APP'], fetchArticles);
}

function* watchFetchRelatedArticles() {
	yield takeLatest(fetchRelatedArticlesAction.type, fetchRelatedArticles);
}

export default function* ArticlesSaga() {
	yield all([fork(watchFetchArticles), fork(watchFetchRelatedArticles)]);
}
