import styled, { css } from 'styled-components';

import colors from 'theme/colors';
import device from 'utils/devices';

export const NewsCard = styled.div`
	display: flex;
	align-items: center;
	max-width: 1440px;
	width: 95%;
	height: 250px;
	background: #ffffff;
	border-radius: 16px;

	@media ${device.laptop} {
		height: 200px;
		width: 90%;
	}

	@media ${device.tablet} {
		width: 85%;
	}

	@media ${device.mobile} {
		width: 70%;
	}
`;

export const NewsImage = styled.img`
	height: 100%;
	aspect-ratio: 1.7/1;
	border-bottom-left-radius: 16px;
	border-top-left-radius: 16px;

	@media ${device.laptopL} {
		height: 100%;
	}

	@media ${device.laptop} {
		height: 100%;
	}

	@media ${device.tablet} {
		width: 0px;
	}
`;

export const ArticalWrapper = styled.div`
	position: relative;
	height: 100%;
	border-bottom-right-radius: 16px;
	border-top-right-radius: 16px;
	padding: 20px;

	@media ${device.laptop} {
		padding: 10px;
	}
`;

export const HeadWrapper = styled.div`
	padding: 0 0 8px;
	white-space: nowrap;
`;

export const Sector = styled.span`
	color: ${colors.blue_text};
	line-height: 120%;
	font-weight: 600;
	padding-right: 10px;
	border-right: 1px solid #b5cfe6;

	@media ${device.laptop} {
		padding: 3px;
		font-size: 12px;
		padding-right: 10px;
		border-right: 1px solid #b5cfe6;
	}
`;

export const Date = styled(Sector)`
	color: ${colors.dark_gray_text};
	padding-left: 10px;
	font-weight: 500;
`;

type ReadTimeProps = {
	noborder?: boolean;
};

export const ReadTime = styled(Sector)<ReadTimeProps>`
	color: ${colors.dark_gray_text};
	padding-left: 10px;
	font-weight: 500;

	${(props) =>
		props.noborder &&
		css`
			border: none;
		`}
`;

export const Title = styled.strong`
	font-size: 20px;
	font-weight: 800;
	line-height: 120%;
	color: ${colors.black_text};

	@media ${device.laptopL} {
		font-size: 20px;
	}

	@media ${device.laptop} {
		font-size: 14px;
	}
`;

export const Content = styled.p`
	max-height: 95px;
	color: ${colors.dark_gray_text};
	font-size: 15px;
	padding: 10px 0;
	overflow: hidden;
	text-overflow: ellipsis;

	@media ${device.laptopL} {
		padding: 10px 0;
		font-size: 16px;
	}

	@media ${device.laptop} {
		max-height: 65px;
		font-size: 12px;
	}
`;

export const TagWrapper = styled.div`
	position: absolute;
	bottom: 20px;
	display: flex;
	justify-content: flex-start;

	@media ${device.laptopL} {
		bottom: 20px;
	}
	@media ${device.laptop} {
		bottom: 10px;
	}
`;

export const Tag = styled.button`
	background: ${colors.tag_bg};
	border: 0.5px solid #b5cfe6;
	border-radius: 200px;
	margin-right: 10px;
	color: ${colors.dark_gray_text};
	font-weight: 600;
	padding: 4px 20px;

	:hover {
		background-color: ${colors.tag_hover_bg};
		transition: 0.4s;
	}

	@media ${device.laptopL} {
		padding: 4px 24px;
	}

	@media ${device.laptop} {
		padding: 3px 10px;
		font-size: 12px;
	}
`;
