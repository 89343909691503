import { Table } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'theme/colors';

export const MarketIndexTableWrapper = styled.div`
	border-radius: 15px;
	margin-top: 27px;
	padding: 18px 30px;
`;

export const CustomMarketIndexTableWrapper = styled.div`
	background-color: ${colors.white};
	border-radius: 15px;
	margin-top: 28px;
	padding: 18px 30px;
`;

export const removeScrollbarArea = css`
	scrollbar-width: none;
	::-webkit-scrollbar {
		-ms-overflow-style: none;
		display: none;
	}
`;

export const CustomMarketIndexTable = styled(Table)`
	border-radius: 15px;
	overflow: scroll;

	${removeScrollbarArea}

	.ant-table-thead > tr > th {
		background: none;
		border-bottom: 0.5px solid ${colors.pale_blue_3};
		color: ${colors.dark_gray_text};
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
	}
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		background-color: #fff;
	}
	.ant-table-tbody > tr > td {
		border-bottom: none;
		color: ${colors.dark_gray_text};
	}
	.ant-table-thead > tr > th,
	.ant-table-tbody > tr > td {
		padding: 14px;
		text-align: right;
		&:first-child {
			padding: 10px;
		}
		&:nth-child(2) {
			text-align: left;
		}
		&:nth-child(3) {
			text-align: center;
		}
	}
` as typeof Table;

export const CategoryTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
`;

export const MarketIndexWrapper = styled.div`
	display: flex;
	gap: 6px;
	width: max-content;
`;

export const MarketIndexSymbol = styled.div`
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
`;

export const MarketIndexFullName = styled.div`
	font-size: 9px;
	font-weight: 400;
	line-height: 11px;
`;
