import * as Styled from './Research.styled';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ArticleResult } from '../ArticleDetailPage/types';
import CarouselCardNews from './components/CarouselCardNews';
import Category from './components/Category';
import NewsCard from './components/NewsCard';
import ScrollToTop from 'react-scroll-to-top';
import { makeSelectArticles } from '../ArticleDetailPage/selectors';
import queryString from 'query-string';
import routes from 'config/routes';
import { useAppSelector } from 'redux/store';

export type categoryType = {
	category: string;
	definition: string;
	subcategory: string[] | null;
};

const categories: categoryType[] = [
	{
		category: 'In-Depth Report',
		definition:
			'VegaX In-depth reports are structured to sort the signal from the noise of today’s crypto markets. Our analysts extensively research a particular sector, protocol, token – even the market as a whole – and deliver prescient, actionable insights to help investors better understand the particular topic.',
		subcategory: [
			'General Market Analysis',
			'Sector In-Depth',
			'Token/Crypto In-Depth',
		],
	},
	{
		category: 'Academic Materials',
		definition:
			'Understanding the fundamentals of how cryptocurrencies actually work is vitally important to having a sense of the overal market. VegaX Academic Materials cover topics that range from the basics of public key cryptography to advanced analysis of current trends in the bitcoin mining industry. Investors can use these pieces to build the foundation of their crypto knowledge!',
		subcategory: ['Basic', 'Advanced'],
	},
	{
		category: 'Issue Analysis',
		definition:
			'Crypto moves fast. VegaX Issue Analysis pieces are meant to keep investors informed of the most impactful events taking place in the industry. While these content pieces are optimized for speedy delivery, analysts still take their time in sourcing credible information and providing actionable insights, where appropriate.',
		subcategory: ['Issue Analysis', 'Issue Analysis'],
	},
];

const ResearchPage = () => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { category: selectedCategory } = queryString.parse(search);
	const newsCardsData = useAppSelector(makeSelectArticles());
	const [subCategory, setSubCategory] = useState('General Market Analysis');
	const [categoryDefinition, setCategoryDefinition] = useState('');
	const [selectedCategoryNewsCards, setSelectedCategoryCards] = useState<
		ArticleResult[]
	>([]);

	useEffect(() => {
		if (newsCardsData) {
			const selectedCategoryCards = newsCardsData.filter(
				(newsCard) => newsCard.category.name === selectedCategory
			);
			setSelectedCategoryCards(selectedCategoryCards);
		}
	}, [newsCardsData, selectedCategory]);

	useEffect(() => {
		if (selectedCategory) {
			const filterCategories = categories.filter(
				(category) => category.category === selectedCategory
			)[0].subcategory;

			if (filterCategories && filterCategories?.length > 0) {
				setSubCategory(filterCategories[0]);
			}

			const selectedCategoryDefinition = categories.filter(
				(category) => category.category === selectedCategory
			)[0].definition;

			setCategoryDefinition(selectedCategoryDefinition);
		}

		if (!selectedCategory) {
			navigate(`${routes.research.path}?category=In-Depth Report`);
		}
	}, [selectedCategory]);

	const setSelectedCategory = (category: string) => {
		navigate(`${routes.research.path}?category=${category}`);
	};

	const setSelectedSubCategory = (subcategory: string) => {
		setSubCategory(subcategory);
	};

	return (
		<Styled.Wrapper>
			<CarouselCardNews news={newsCardsData} />
			<Category
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
				setSelectedSubCategory={setSelectedSubCategory}
				subCategory={subCategory}
				categoryDefinition={categoryDefinition}
			/>
			<NewsCard
				selectedCategoryNewsCards={selectedCategoryNewsCards}
				subCategory={subCategory}
			/>
			<ScrollToTop
				smooth
				color="#02588B"
				top={100}
				height="20px"
				width="28px"
			/>
		</Styled.Wrapper>
	);
};

export default ResearchPage;
