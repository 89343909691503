const size = {
	mobileM: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px',
};

const device = {
	mobile: `(max-width: ${size.mobileM})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopL: `(max-width:${size.laptopL})`,
	desktop: `(max-width: ${size.desktop})`,
};

export default device;
