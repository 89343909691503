import { Spin } from 'antd';
import {
	selectMarketCategoryList,
	selectMarketIndexErrorMessage,
	selectMarketIndexLoading,
} from 'page/MarketPage/selectors';
import React from 'react';
import { useAppSelector } from 'redux/store';
import useErrorModal from 'utils/hooks/useErrorModal';

import MarketIndexTable from './MarketIndexTable';

const MarketIndexContents = () => {
	const categoryList = useAppSelector(selectMarketCategoryList);
	const isLoading = useAppSelector(selectMarketIndexLoading);
	const errorMessage = useAppSelector(selectMarketIndexErrorMessage);
	useErrorModal(errorMessage);

	return (
		<div>
			{isLoading && (
				<Spin
					size="large"
					style={{ width: '100%', paddingTop: '20%', paddingBottom: '20%' }}
				/>
			)}
			{!isLoading &&
				categoryList.map((categoryName, idx) => (
					<MarketIndexTable
						key={`categoryName${idx}`}
						categoryName={categoryName}
					/>
				))}
		</div>
	);
};

export default MarketIndexContents;
