import { Article } from 'page/ArticleDetailPage/types';
import { MarketIndex } from 'page/MarketPage/types';
import HttpClient from 'utils/http-client';

class MainApi extends HttpClient {
	private static classInstance?: MainApi;

	private constructor() {
		super(`https://api-terminal-test.vegaxh.com/api/v1`);
	}

	public static getInstance() {
		if (!this.classInstance) {
			this.classInstance = new MainApi();
		}

		return this.classInstance;
	}

	public fetchArticles = () => this.instance.get<Article>('/research-pages');

	public fetchRelatedArticles = (id: string) =>
		this.instance.get<Article>(`/research-pages/related-pages/${id}`);

	public fetchIndexes = () => this.instance.get<Array<MarketIndex>>('/indexes');
}

const mainApi = MainApi.getInstance();

export default mainApi;
