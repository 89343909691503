import React from 'react';

import colors from 'theme/colors';
import { FiatCurrency } from 'page/MarketPage/types';

type Props = {
	value?: number | null;
	currency?: FiatCurrency;
	isPercent?: boolean;
	suffix?: string;
	withCents?: boolean;
};

export const formatCurrencyNumber = (
	number: number,
	withCents = true,
	currency = 'USD',
	locale = 'en-US'
): string => {
	return Number(number).toLocaleString(locale, {
		style: 'currency',
		currency,
		minimumFractionDigits: withCents ? 2 : 0,
		maximumFractionDigits: withCents ? 2 : 0,
	});
};

export const formatPercentNumber = (number: number): string => {
	return (Number(number) / 100).toLocaleString('en-US', {
		style: 'percent',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	});
};

export const formatNumber = (number: number, maxFractionDigits = 4): string => {
	return Number(number).toLocaleString('en-US', {
		style: 'decimal',
		minimumFractionDigits: 0,
		maximumFractionDigits: maxFractionDigits,
	});
};

const ChangesView: React.FC<Props> = ({
	value = null,
	currency,
	isPercent = false,
	withCents = false,
}) => {
	if (value === null) {
		return <span>-</span>;
	}

	let newValue = value;
	let prefix = '';

	const isIncrease = value > 0;
	const isDecrease = value < 0;

	if (isDecrease) {
		newValue *= -1;
		prefix = '-';
	}
	if (isIncrease) {
		prefix = '+';
	}

	let formatValue;
	if (currency) {
		formatValue = formatCurrencyNumber(newValue, withCents, currency);
	} else if (isPercent) {
		formatValue = formatPercentNumber(newValue);
	} else {
		formatValue = formatNumber(newValue, 2);
	}

	if (isPercent) {
		return (
			<span
				style={{
					color: isIncrease ? colors.mint_1 : colors.red_2,
					fontWeight: '600',
					fontSize: '14px',
					lineHeight: '17px',
				}}
			>{`${prefix}${formatValue}`}</span>
		);
	} else {
		return (
			<span
				style={{
					color: colors.black_text,
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '17px',
				}}
			>{`${formatValue}`}</span>
		);
	}
};
export default ChangesView;
