import { combineReducers } from '@reduxjs/toolkit';

import { articleReducer } from 'page/ArticleDetailPage/slice';
import { marketIndexReducer } from 'page/MarketPage/slice';

const reducers = {
	article: articleReducer,
	marketIndex: marketIndexReducer,
};

const createRootReducer = () =>
	combineReducers({
		...reducers,
	});

export { createRootReducer };
