import styled from 'styled-components';

import colors from 'theme/colors';

export const Header = styled.header`
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	background: ${colors.navbar_bg};
	height: 73px;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
`;

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding: 0 20px 0 20px;
`;

export const Link = styled.a`
	display: flex;
	align-items: center;
	color: black;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.1em;

	span {
		color: black;
		margin-left: 8px;
	}
`;

export const Logo = styled.img`
	width: 70px;
`;

export const HamburgerButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	width: 30px;
`;

export const NavList = styled.ul`
	display: flex;
	align-items: flex-end;
	flex-direction: column;
`;

export const NavItem = styled.a`
	color: ${colors.black_text};
	font-size: 16px;
	font-family: Montserrat;
	font-weight: 400;
	letter-spacing: 0.05em;
	white-space: nowrap;
	margin-top: 20px;
	margin-right: 10px;
`;

export const Btn = styled.button`
	display: none; // not using now
	width: 150px;
	height: 38px;
	border-radius: 50px;
	border: none;
	background: ${colors.button_bg};
	color: ${colors.white_text};
	font-weight: 700;
	margin-top: 20px;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

	:hover {
		background-color: ${colors.button_hover_bg};
		transition: 0.4s;
	}
`;
