import DesktopHeader from './DesktopHeader/DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';
import React from 'react';
import device from 'utils/devices';
import routes from 'config/routes';
import useMediaQuery from 'utils/hooks/useMedia';

export type navigation = {
	name: string;
	link: string;
};

export type HeaderProps = {
	navs: navigation[];
};

const NAVS = [
	// {
	// 	name: 'Dashboard',
	// 	link: routes.dashboard.path,
	// },

	{
		name: 'Research',
		link: routes.research.path,
	},
	{
		name: 'Market Index',
		link: routes.market.path,
	},
];

const Header = () => {
	const isTablet = useMediaQuery(`${device.tablet}`);

	return (
		<div>
			{isTablet ? <MobileHeader navs={NAVS} /> : <DesktopHeader navs={NAVS} />}
		</div>
	);
};

export default Header;
