import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/store';

import {
	MarketIndexTableRow,
	selectMarketIndexTableDataByCategory,
} from 'page/MarketPage/selectors';
import {
	CategoryTitle,
	CustomMarketIndexTable,
	CustomMarketIndexTableWrapper,
	MarketIndexTableWrapper,
} from './MarketIndexTable.styled';
import { ColumnsType } from 'antd/lib/table';
import ChangesView from 'components/ChangesView';
import { TargetAsset } from 'page/MarketPage/types';
import UnderlyingAssetsView from '../UnderlyingAssetsView';
import TableIndex from './TableIndex';

interface MarketIndexTableProps {
	categoryName: string;
}

const MarketIndexTable = ({ categoryName }: MarketIndexTableProps) => {
	const marketIndexTableDataByCategory = useAppSelector((state) =>
		selectMarketIndexTableDataByCategory(state, categoryName)
	);

	const columns = useMemo(() => {
		const data: ColumnsType<MarketIndexTableRow> = [
			{
				title: '#',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: 'Index',
				dataIndex: 'index',
				key: 'index',
				render: ([symbol, fullName]: [string, string]) => {
					return <TableIndex symbol={symbol} fullName={fullName} />;
				},
			},
			{
				title: 'Underlying Assets',
				dataIndex: 'underlyingAssets',
				key: 'underlyingAssets',
				render: (assets: TargetAsset[], record: MarketIndexTableRow) => {
					return <UnderlyingAssetsView assets={assets} rowData={record} />;
				},
			},
			{
				title: 'Value',
				dataIndex: 'value',
				key: 'value',
				render: (value: number) => <ChangesView value={value} withCents />,
			},
			{
				title: '24h change',
				dataIndex: 'change24h',
				key: 'change24h',
				render: (value: number) => <ChangesView value={value} isPercent />,
			},
			{
				title: '7d trend',
				dataIndex: 'trend7d',
				key: 'trend7d',
				render: (trend7d: string) => {
					return (
						<div>
							<img width="70px" height="18px" src={trend7d} alt="7days graph" />
						</div>
					);
				},
			},
			{
				title: '7d change',
				dataIndex: 'change7d',
				key: 'change7d',
				render: (value: number) => <ChangesView value={value} isPercent />,
			},
			{
				title: '30d change',
				dataIndex: 'change30d',
				key: 'change30d',
				render: (value: number) => <ChangesView value={value} isPercent />,
			},
			{
				title: 'YTD change',
				dataIndex: 'changeYtd',
				key: 'changeYtd',
				render: (value: number) => <ChangesView value={value} isPercent />,
			},
		];
		return data;
	}, []);

	return (
		<MarketIndexTableWrapper>
			{marketIndexTableDataByCategory.length > 0 && (
				<>
					<CategoryTitle>{categoryName}</CategoryTitle>
					<CustomMarketIndexTableWrapper>
						<CustomMarketIndexTable
							columns={columns}
							dataSource={marketIndexTableDataByCategory}
							pagination={false}
						/>
					</CustomMarketIndexTableWrapper>
				</>
			)}
		</MarketIndexTableWrapper>
	);
};

export default MarketIndexTable;
