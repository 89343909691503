import React, { useMemo } from 'react';

import * as Styled from './Category.styled';

type CategoryProps = {
	selectedCategory: string | (string | null)[] | null;
	setSelectedCategory: (category: string) => void;
	setSelectedSubCategory: (sub: string) => void;
	subCategory: string;
	categoryDefinition: string;
};

const InDepthMenu = ({
	setSelectedSubCategory,
	subCategory,
}: {
	setSelectedSubCategory: (sub: string) => void;
	subCategory: string;
}) => {
	return (
		<Styled.SubCategoryContainer>
			<Styled.Text
				active={subCategory === 'General Market Analysis'}
				onClick={() => setSelectedSubCategory('General Market Analysis')}
			>
				General Market Analysis
			</Styled.Text>
			<Styled.Text
				active={subCategory === 'Sector In-Depth'}
				onClick={() => setSelectedSubCategory('Sector In-Depth')}
			>
				Sector In-Depth
			</Styled.Text>
			<Styled.Text
				active={subCategory === 'Token/Crypto In-Depth'}
				onClick={() => setSelectedSubCategory('Token/Crypto In-Depth')}
			>
				Token/Crypto In-Depth
			</Styled.Text>
		</Styled.SubCategoryContainer>
	);
};
const AcademicMaterialsMenu = ({
	setSelectedSubCategory,
	subCategory,
}: {
	setSelectedSubCategory: (subcategory: string) => void;
	subCategory: string;
}) => {
	return (
		<Styled.SubCategoryContainer>
			<Styled.Text
				active={subCategory === 'Basic'}
				onClick={() => setSelectedSubCategory('Basic')}
			>
				Basic
			</Styled.Text>
			<Styled.Text
				active={subCategory === 'Advanced'}
				onClick={() => setSelectedSubCategory('Advanced')}
			>
				Advanced
			</Styled.Text>
		</Styled.SubCategoryContainer>
	);
};

const Category = ({
	selectedCategory,
	setSelectedCategory,
	setSelectedSubCategory,
	subCategory,
	categoryDefinition,
}: CategoryProps) => {
	const renderSubCategory = useMemo(() => {
		switch (selectedCategory) {
			case 'In-Depth Report':
				return (
					<InDepthMenu
						setSelectedSubCategory={setSelectedSubCategory}
						subCategory={subCategory}
					/>
				);
			case 'Academic Materials':
				return (
					<AcademicMaterialsMenu
						setSelectedSubCategory={setSelectedSubCategory}
						subCategory={subCategory}
					/>
				);
			default:
				return null;
		}
	}, [selectedCategory, setSelectedSubCategory, subCategory]);

	return (
		<Styled.CategoryWrapper>
			<Styled.CategoryContainer>
				<Styled.DefinitionWrapper>
					<Styled.Title>{selectedCategory}</Styled.Title>
					<Styled.Definition>{categoryDefinition}</Styled.Definition>
				</Styled.DefinitionWrapper>
				<Styled.ButtonWrapper>
					<Styled.ButtonsWrapper>
						<Styled.Button
							active={selectedCategory === 'In-Depth Report'}
							onClick={() => setSelectedCategory('In-Depth Report')}
						>
							In-Depth Report
						</Styled.Button>
						<Styled.Button
							active={selectedCategory === 'Academic Materials'}
							onClick={() => setSelectedCategory('Academic Materials')}
						>
							Academic Materials
						</Styled.Button>
						<Styled.Button
							active={selectedCategory === 'Issue Analysis'}
							onClick={() => setSelectedCategory('Issue Analysis')}
						>
							Issue Analysis
						</Styled.Button>
					</Styled.ButtonsWrapper>
				</Styled.ButtonWrapper>
			</Styled.CategoryContainer>
			<Styled.SubCategoryWrapper>{renderSubCategory}</Styled.SubCategoryWrapper>
		</Styled.CategoryWrapper>
	);
};

export default Category;
