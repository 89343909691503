import * as CommonStyled from 'common.styled';
import * as Styled from './Footer.styled';

import banner from 'assets/icons/footerbanner.webp';

const SHOW_CATEGORY_LIST = ['Company', 'Socials'];
const FOOT_LINK_LIST = [
	{
		category: 'Company',
		sub_category: [
			{
				name: 'Website',
				path: 'https://vegaxholdings.com',
			},
			{
				name: 'Careers',
				path: 'https://konstellation.notion.site/Careers-at-Konstellation-English-aab08b68be0545f5ba5441ce947bc01f',
			},
			{
				name: 'Media Press',
				path: 'https://vegaxholdings.start.page',
			},
		],
	},
	{
		category: 'Plans',
		sub_category: [
			{
				name: 'Pro',
				path: '/',
			},
			{
				name: 'Enterprise',
				path: '/',
			},
		],
	},
	{
		category: 'Socials',
		sub_category: [
			{
				name: 'Twitter',
				path: 'https://twitter.com/vegaxholdings?lang=en',
			},
			{
				name: 'Facebook',
				path: 'https://www.facebook.com/VegaXHoldings',
			},
			{
				name: 'Linkedin',
				path: 'https://www.linkedin.com/company/vegaxholdings/',
			},
		],
	},
	{
		category: 'Consultation',
		sub_category: [
			{
				name: 'License',
				path: '/',
			},
			{
				name: 'Investment Products',
				path: '/',
			},
			{
				name: 'Help',
				path: '/',
			},
		],
	},
].filter((one) => SHOW_CATEGORY_LIST.includes(one.category));

const Footer = () => {
	return (
		<Styled.Footer>
			<Styled.Container>
				<Styled.CategorysWrapper>
					{FOOT_LINK_LIST.map((item) => (
						<Styled.CategoryWrapper key={item.category}>
							<h4>{item.category}</h4>
							<ul>
								{item.sub_category.map((sub) => (
									<a
										href={sub.path}
										target="_blank"
										rel="noopener noreferrer"
										key={sub.name}
									>
										<li>{sub.name}</li>
									</a>
								))}
							</ul>
						</Styled.CategoryWrapper>
					))}
				</Styled.CategorysWrapper>
				<Styled.InputWrapper>
					<h4>Subscribe to our research</h4>
					<CommonStyled.Wrapper position="relative">
						<Styled.Input placeholder="Enter your email address" />
						<Styled.SubscribeButton>SUBSCRIBE</Styled.SubscribeButton>
					</CommonStyled.Wrapper>
				</Styled.InputWrapper>
				<Styled.Banner src={banner} alt="banner" />
			</Styled.Container>
		</Styled.Footer>
	);
};

export default Footer;
