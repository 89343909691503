import * as Styled from './MobileNewsCard.styled';

import { ArticleResult, Tag } from 'page/ArticleDetailPage/types';

import { Link } from 'react-router-dom';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { fetchRelatedArticles } from 'page/ArticleDetailPage/slice';
import { nanoid } from 'nanoid';
import routes from 'config/routes';
import { useAppDispatch } from 'redux/store';

type MobileNewsCardProps = {
	news: ArticleResult;
	section?: boolean;
	related?: boolean;
};

const MobileNewsCard = ({ news, section, related }: MobileNewsCardProps) => {
	const dispatch = useAppDispatch();
	const publishDate = new Date(news.lastPublishedAt);

	return (
		<Link
			to={`${routes.research.path}${news.id}?category=${news.category.name}`}
			onClick={() => dispatch(fetchRelatedArticles(news.id))}
		>
			<Styled.NewsCard section={section} related={related}>
				<Styled.NewsImage src={news.banner.image.url} alt="thumbnail" />
				<Styled.ArticalWrapper>
					<Styled.Sector>{news.category.name}</Styled.Sector>
					<Styled.HeadWrapper>
						<Styled.Date>{`${publishDate?.toDateString().split(' ')[2]}
						 	${publishDate?.toDateString().split(' ')[1]}
							 ${publishDate?.toDateString().split(' ')[3]}
							`}</Styled.Date>
						<Styled.ReadTime>{news.readTime}</Styled.ReadTime>
					</Styled.HeadWrapper>
					<Styled.Title>{news.header}</Styled.Title>
					<Styled.Content related={related}>
						{ReactHtmlParser(news.truncatedContent)}
					</Styled.Content>
					<Styled.TagWrapper>
						{news.tags?.map((item: Tag) => (
							<Link to={`/tag/${item.name}`} key={nanoid()}>
								<Styled.Tag>{item.name}</Styled.Tag>
							</Link>
						))}
					</Styled.TagWrapper>
				</Styled.ArticalWrapper>
			</Styled.NewsCard>
		</Link>
	);
};

export default MobileNewsCard;
