import { Tooltip } from 'antd';
import React from 'react';

import { MarketIndexTableRow } from 'page/MarketPage/selectors';
import { AssetInfo, TargetAsset, Token } from 'page/MarketPage/types';
import { getCurrencyIcon } from 'utils/coinInfo';
import styled from 'styled-components';

function isToken(target: unknown): target is Token {
	return target !== undefined && 'weight' in (target as Token);
}

function firstUpperCase(text: string) {
	return text.replace(/\w/, (w) => w.toUpperCase());
}

const UnderlyingAssetsWrapper = styled.div`
	display: flex;
	gap: 3px;
	justify-content: center;
`;

function getTokenTooltipText(
	assetInfo: AssetInfo,
	symbol: string,
	name: string
): string {
	const assetInfoData = assetInfo[symbol];
	const tokenWeightText = isToken(assetInfoData)
		? ` : ${(parseFloat(assetInfoData.weight.toString()) * 100).toFixed(2)}%`
		: '';

	return `${firstUpperCase(name)}${tokenWeightText}`;
}

interface UnderlyingAssetsViewProps {
	assets: TargetAsset[];
	rowData: MarketIndexTableRow;
}

function UnderlyingAssetsView({
	assets,
	rowData: { assetInfo },
}: UnderlyingAssetsViewProps) {
	return (
		<UnderlyingAssetsWrapper>
			{assets.map(([symbol, name], idx) => {
				const tokenTooltipText = getTokenTooltipText(assetInfo, symbol, name);

				return (
					<span key={symbol + name + idx}>
						<Tooltip title={tokenTooltipText}>
							<img
								alt={name}
								height="18px"
								width="18px"
								src={getCurrencyIcon(symbol)}
							/>
						</Tooltip>
					</span>
				);
			})}
		</UnderlyingAssetsWrapper>
	);
}

export default UnderlyingAssetsView;
