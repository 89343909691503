import * as Styled from './CarouselCardNews.styled';

import { Carousel, Spin } from 'antd';

import { ArticleResult } from 'page/ArticleDetailPage/types';
import DesktopNewsCard from 'components/NewsCard/DesktopNewsCard/DesktopNewsCard';
import MobileNewsCard from 'components/NewsCard/MobileNewsCard/MobileNewsCard';
import React from 'react';
import device from 'utils/devices';
import useMediaQuery from 'utils/hooks/useMedia';

type CarouselCardNewsProps = { news: ArticleResult[] };

const CarouselCardNews = ({ news }: CarouselCardNewsProps) => {
	const isMobile = useMediaQuery(`${device.mobile}`);
	return (
		<Styled.NewsCardSection>
			{news ? (
				<Styled.CarouselWrapper>
					<Carousel arrows autoplay>
						{news.slice(0, 5).map((newsInfo: ArticleResult) => {
							return (
								<div>
									{isMobile ? (
										<MobileNewsCard key={newsInfo.id} news={newsInfo} />
									) : (
										<DesktopNewsCard key={newsInfo.id} news={newsInfo} />
									)}
								</div>
							);
						})}
					</Carousel>
				</Styled.CarouselWrapper>
			) : (
				<Spin size="large" style={{ width: '100%', marginTop: '110px' }} />
			)}
		</Styled.NewsCardSection>
	);
};

export default CarouselCardNews;
