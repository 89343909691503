import { all, fork } from 'redux-saga/effects';

import ArticlesSaga from 'page/ArticleDetailPage/saga';
import MarketIndexesSaga from 'page/MarketPage/saga';

function* rootSaga() {
	yield all([fork(ArticlesSaga), fork(MarketIndexesSaga)]);
}

export { rootSaga };
