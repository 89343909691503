import colors from '../../theme/colors';
import device from '../../utils/devices';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1440px;
	padding: 30px 20px 30px 20px;
`;

export const Title = styled.strong`
	color: ${colors.black_text};
	font-weight: 700;
	font-size: 34px;
	line-height: 120%;
	letter-spacing: -0.25px;
	padding-top: 30px;

	@media ${device.tablet} {
		font-size: 28px;
	}
`;

export const BackButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const BackButton = styled.button`
	background-color: transparent;
	border: none;
`;

export const NoArticleWrapper = styled.div`
	height: 50vh;
	width: 100%;
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NoArticleContainer = styled.img`
	width: 500px;
`;

export const ButtonWrapper = styled.div`
	margin-top: 50px;
	display: flex;
	justify-content: center;
`;

export const Button = styled.button`
	background: ${colors.primary_bg};
	border: 2px solid ${colors.button_bg};
	color: ${colors.button_bg};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 28px;
	gap: 10px;
	width: 232px;
	height: 49px;
	border-radius: 200px;
	font-weight: 700;
	font-size: 16px;
	line-height: 150%;

	:hover {
		background: ${colors.button_bg};
		transition: 0.3s;
		color: ${colors.white_text};
	}

	:active {
		background: ${colors.button_hover_bg};
		transition: 0.3s;
	}
`;
