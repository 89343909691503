import colors from 'theme/colors';
import device from 'utils/devices';
import styled from 'styled-components';

export const NewsCardSection = styled.section`
	width: 100%;
	height: 350px;
	max-width: 1440px;
`;

export const CarouselWrapper = styled.div`
	.ant-carousel {
		.slick-slider {
			display: flex;
			padding: 0px 0px 0px 70px;

			@media ${device.mobile} {
				padding: 0px 0px 0px 0px;
			}

			.slick-prev {
				left: 10px;
				z-index: 2;
				color: transparent;
				height: 32px;
				width: 32px;
				background-image: url(/left-arrow.svg);
			}

			.slick-next {
				right: 10px;
				z-index: 2;
				color: transparent;
				height: 32px;
				width: 32px;
				background-image: url(/right-arrow.svg);
			}
		}

		.slick-dots-bottom {
			bottom: -60px;
		}

		.slick-dots {
			li {
				button {
					width: 7px;
					height: 7px;
					border-radius: 50%;
					background-color: ${colors.blue_text};
				}
			}
		}
	}
`;
