import styled, { css } from 'styled-components';

import colors from 'theme/colors';
import device from 'utils/devices';

export const CategoryWrapper = styled.section`
	padding: 10px 10px 0px 10px;
`;

export const CategoryContainer = styled.section`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 1130px) {
		justify-content: space-between;
	}

	@media ${device.mobile} {
		flex-direction: column;
	}
`;

export const DefinitionWrapper = styled.div`
	width: 50%;

	@media ${device.mobile} {
		width: 100%;
		margin-top: 330px;
	}
`;

export const ButtonWrapper = styled.div`
	margin-top: 50px;
	width: 50%;

	@media (max-width: 1130px) {
		width: 160px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	@media ${device.mobile} {
		margin-top: 20px;
		width: 100%;
		width: 180px;
		display: flex;
	}
`;

export const Title = styled.strong`
	color: ${colors.black_text};
	font-weight: 700;
	font-size: 34px;
	line-height: 120%;
	letter-spacing: -0.25px;

	@media ${device.tablet} {
		font-size: 28px;
	}
`;

export const Definition = styled.div`
	margin-top: 30px;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: ${colors.dark_gray_text};

	@media ${device.tablet} {
		font-size: 14px;
	}
`;

export const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-top: 20px;
	margin-bottom: 20px;

	@media ${device.mobile} {
		justify-content: flex-start;
	}
`;

type ButtonProps = {
	active?: boolean;
};

export const Button = styled.button<ButtonProps>`
	background: ${colors.white_text};
	color: #525252ba;
	border: 0.25px solid #b5cfe6;
	border-radius: 200px;
	padding: 5px 20px;
	white-space: nowrap;
	margin-right: 10px;
	margin-top: 5px;
	font-weight: 700;
	line-height: 175%;
	letter-spacing: -0.5px;

	cursor: pointer;

	@media ${device.tablet} {
		font-size: 12px;
	}

	${(props) =>
		props.active &&
		css`
			background-color: ${colors.filter_hover_bg};
			border: 1px solid ${colors.filter_hover_bg};
			color: ${colors.white_text};
			font-weight: 700;
		`}

	:hover {
		background-color: ${colors.filter_hover_bg};
		border: 1px solid ${colors.filter_hover_bg};
		transition: 0.4s;
	}
`;

export const SubCategoryWrapper = styled.div`
	position: sticky;
	top: 0;
	padding: 32px 0px 12px 0px;
`;

export const SubCategoryContainer = styled.div`
	border-bottom: 0.5px solid #b5cfe6;
	display: flex;
	justify-content: flex-start;
`;

type TextProps = {
	active?: boolean;
};

export const Text = styled.span<TextProps>`
	color: ${colors.dark_gray_text};
	font-weight: 400;
	font-size: 15px;
	line-height: 175%;
	padding: 0px 20px;
	border-bottom: 1px solid #b5cfe66e;
	cursor: pointer;

	@media ${device.tablet} {
		font-size: 12px;
	}

	${(props) =>
		props.active &&
		css`
			text-shadow: 0 0 1px black;
			border-bottom: 2px solid ${colors.blue_text};
		`}
`;
