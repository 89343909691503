import * as Styled from './DesktopNewsCard.styled';

import { ArticleResult, Tag } from 'page/ArticleDetailPage/types';

import { Link } from 'react-router-dom';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { nanoid } from 'nanoid';
import routes from 'config/routes';

type NewsCardProps = { news: ArticleResult };

const DesktopNewsCard = ({ news }: NewsCardProps) => {
	const publishDate = new Date(news.lastPublishedAt);
	return (
		<Link
			to={`${routes.research.path}${news.id}?category=${news.category.name}`}
		>
			<Styled.NewsCard>
				<Styled.NewsImage src={news.banner.image.url} alt="thumbnail" />
				<Styled.ArticalWrapper>
					<Styled.HeadWrapper>
						<Styled.Sector>{news.category.name}</Styled.Sector>
						<Styled.Date>{`${publishDate?.toDateString().split(' ')[2]}
						 	${publishDate?.toDateString().split(' ')[1]}
							 ${publishDate?.toDateString().split(' ')[3]}
							`}</Styled.Date>
						<Styled.ReadTime noborder>{news.readTime}</Styled.ReadTime>
					</Styled.HeadWrapper>
					<Styled.Title>{news.header}</Styled.Title>
					<Styled.Content>
						{ReactHtmlParser(news.truncatedContent)}
					</Styled.Content>
					<Styled.TagWrapper>
						{news.tags?.map((item: Tag) => (
							<Link to={`tag/${item.name}`} key={nanoid()}>
								<Styled.Tag>{item.name}</Styled.Tag>
							</Link>
						))}
					</Styled.TagWrapper>
				</Styled.ArticalWrapper>
			</Styled.NewsCard>
		</Link>
	);
};

export default DesktopNewsCard;
