import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MarketIndex, MarketIndexState } from './types';

export const initialState: MarketIndexState = {
	data: [],
	loading: false,
	errorMessage: '',
};

const marketIndexSlice = createSlice({
	name: 'marketIndex',
	initialState,
	reducers: {
		fetchIndexes(state) {
			state.loading = true;
			state.errorMessage = '';
		},
		fetchIndexesSuccess(state, action: PayloadAction<Array<MarketIndex>>) {
			state.loading = false;
			state.data = action.payload;
			state.errorMessage = '';
		},
		fetchIndexesFailure(state, action) {
			state.loading = false;
			state.errorMessage = action.payload;
		},
	},
});

export const marketIndexActions = marketIndexSlice.actions;
export const marketIndexReducer = marketIndexSlice.reducer;
