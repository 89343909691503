import {
	BackButton,
	BackButtonWrapper,
	Button,
	ButtonWrapper,
	NoArticleContainer,
	NoArticleWrapper,
	Title,
	Wrapper,
} from './TagPage.styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useState } from 'react';

import { BackImagewrapper } from '../ArticleDetailPage/ArticleDetailPage.styled';
import MobileNewsCard from '../../components/NewsCard/MobileNewsCard/MobileNewsCard';
import { NewsCardWrapper } from '../ResearchPage/components/NewsCard/NewsCard.styled';
import backarrow from '../../assets/icons/back-arrow.svg';
import { makeSelectArticles } from '../ArticleDetailPage/selectors';
import noarticle from '../../assets/icons/noarticle.svg';
import routes from '../../config/routes';
import { useAppSelector } from '../../redux/store';

const DISPLAY_ARTICLE_OFFSET = 8;

const TagPage = () => {
	const allArticles = useAppSelector(makeSelectArticles());
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [numberOfArticle, setNumberOfArticle] = useState(
		DISPLAY_ARTICLE_OFFSET
	);

	const TagsNewsCards =
		allArticles &&
		allArticles.filter(
			({ tags }) => (tags?.filter((tag) => tag.name === id) ?? []).length > 0
		);

	const showMoreArticle = useCallback(() => {
		setNumberOfArticle(numberOfArticle + DISPLAY_ARTICLE_OFFSET);
	}, [setNumberOfArticle, numberOfArticle]);
	return (
		<Wrapper>
			<BackButtonWrapper>
				<BackButton onClick={() => navigate(-1)}>
					<BackImagewrapper>
						<img src={backarrow} alt="back_arrow" />
						<span>Back to All Research</span>
					</BackImagewrapper>
				</BackButton>
			</BackButtonWrapper>
			<Title>{id}</Title>
			<NewsCardWrapper>
				{TagsNewsCards && TagsNewsCards.length > 0 ? (
					TagsNewsCards.slice(0, numberOfArticle).map((card) => (
						<MobileNewsCard section news={card} />
					))
				) : (
					<NoArticleWrapper>
						<NoArticleContainer src={noarticle} alt="noarticle" />
					</NoArticleWrapper>
				)}
			</NewsCardWrapper>
			<ButtonWrapper>
				{TagsNewsCards &&
				TagsNewsCards.length > 8 &&
				numberOfArticle < TagsNewsCards.length ? (
					<Button onClick={showMoreArticle}>Load More</Button>
				) : null}
			</ButtonWrapper>
		</Wrapper>
	);
};

export default TagPage;
