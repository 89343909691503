import { Article, ArticlesState } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
/* eslint-disable no-param-reassign */

export const initialState: ArticlesState = {
	article: {} as Article,
	relatedArticle: {} as Article,
	loading: false,
	error: null,
};

const articleSlice = createSlice({
	name: 'article',
	initialState,
	reducers: {
		fetchArticles(state) {
			state.loading = true;
		},
		fetchArticlesSuccess(state, action: PayloadAction<Article>) {
			state.loading = false;
			state.article = action.payload;
		},
		fetchArticlesFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fetchRelatedArticles(state, action: PayloadAction<number | undefined>) {
			state.loading = true;
		},
		fetchRelatedArticlesSuccess(state, action: PayloadAction<Article>) {
			state.loading = false;
			state.relatedArticle = action.payload;
		},
		fetchRelatedArticlesFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchArticles,
	fetchArticlesSuccess,
	fetchArticlesFailure,
	fetchRelatedArticles,
	fetchRelatedArticlesSuccess,
	fetchRelatedArticlesFailure,
} = articleSlice.actions;
export const articleReducer = articleSlice.reducer;
