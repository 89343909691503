import VBXM from 'assets/icons/VBXM.svg';
import VC100X from 'assets/icons/VC100X.svg';
import VCUY from 'assets/icons/VCUY.svg';
import VDARCX from 'assets/icons/VDARCX.svg';
import VDBX from 'assets/icons/VDBX.svg';
import VDCX from 'assets/icons/VDCX.svg';
import VDUY from 'assets/icons/VDUY.svg';
import VEXM from 'assets/icons/VEXM.svg';
import VLNX from 'assets/icons/VLNX.svg';
import VNFTX from 'assets/icons/VNFTX.svg';
import VNMX from 'assets/icons/VNMX.svg';
import VSCPX from 'assets/icons/VSCPX.svg';
import VSOLX from 'assets/icons/VSOLX.svg';

type MarketIndexIcon = {
	symbol: string;
	image: string;
	alt: string;
};

export const marketIndexIcons: { [symbol: string]: MarketIndexIcon } = {
	VBXM: {
		symbol: 'VBXM',
		image: VBXM,
		alt: 'VBXM',
	},
	VC100X: {
		symbol: 'VC100X',
		image: VC100X,
		alt: 'VC100X',
	},
	VCUY: {
		symbol: 'VCUY',
		image: VCUY,
		alt: 'VCUY',
	},
	VDARCX: {
		symbol: 'VDARCX',
		image: VDARCX,
		alt: 'VDARCX',
	},
	VDBX: {
		symbol: 'VDBX',
		image: VDBX,
		alt: 'VDBX',
	},
	VDCX: {
		symbol: 'VDCX',
		image: VDCX,
		alt: 'VDCX',
	},
	VDUY: {
		symbol: 'VDUY',
		image: VDUY,
		alt: 'VDUY',
	},
	VEXM: {
		symbol: 'VEXM',
		image: VEXM,
		alt: 'VEXM',
	},
	VLNX: {
		symbol: 'VLNX',
		image: VLNX,
		alt: 'VLNX',
	},
	VNFTX: {
		symbol: 'VNFTX',
		image: VNFTX,
		alt: 'VNFTX',
	},
	VNMX: {
		symbol: 'VNMX',
		image: VNMX,
		alt: 'VNMX',
	},
	VSCPX: {
		symbol: 'VSCPX',
		image: VSCPX,
		alt: 'VSCPX',
	},
	VSOLX: {
		symbol: 'VSOLX',
		image: VSOLX,
		alt: 'VSOLX',
	},
};

export const getMarketIndexIcons = (symbol: string) => {
	return marketIndexIcons[symbol];
};

export const getMarketIndexIconsImagePath = (symbol: string) => {
	return getMarketIndexIcons(symbol.toUpperCase());
};
