import * as Styled from './MobileHeader.styled';

import { HeaderProps, navigation } from '../Header';
import React, { useState } from 'react';

import { Drawer } from 'antd';
import hamburger from 'assets/icons/hamburger.svg';
import logo from 'assets/icons/logo.svg';
import routes from 'config/routes';

const MobileHeader = ({ navs }: HeaderProps) => {
	const [visible, setVisible] = useState(false);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	return (
		<>
			<Styled.Header>
				<Styled.Container>
					<Styled.Link href={routes.research.path}>
						<Styled.Logo src={logo} alt="logo" />
						<span>TERMINAL</span>
					</Styled.Link>
					<Styled.HamburgerButton onClick={showDrawer}>
						<img src={hamburger} alt="menu" />
					</Styled.HamburgerButton>
				</Styled.Container>
			</Styled.Header>
			<Drawer
				placement="right"
				onClose={onClose}
				visible={visible}
				width="80%"
				headerStyle={{ border: 'none' }}
			>
				<Styled.NavList>
					{navs.map((nav: navigation) => (
						<Styled.NavItem href={nav.link} key={nav.name}>
							{nav.name}
						</Styled.NavItem>
					))}
					<Styled.Btn>Go Pro</Styled.Btn>
				</Styled.NavList>
			</Drawer>
		</>
	);
};

export default MobileHeader;
