export const socialMedia: {
	name: string;
	link: string;
	image: string;
	alt: string;
}[] = [
	{
		name: 'facebook',
		link: 'https://web.facebook.com/VegaXHoldings/?_rdc=1&_rdr',
		image: '/Facebook.svg',
		alt: 'facebook',
	},
	{
		name: 'linkedin',
		link: 'https://www.linkedin.com/company/vegaxholdings/',
		image: '/LinkedIn.svg',
		alt: 'linkedin',
	},
	{
		name: 'medium',
		link: 'https://vegaxholdings.medium.com/',
		image: '/Medium.svg',
		alt: 'medium',
	},
	{
		name: 'twitter',
		link: 'https://twitter.com/VegaXHoldings',
		image: '/Twitter.svg',
		alt: 'twitter',
	},
];
