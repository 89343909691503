import styled from 'styled-components';

import colors from 'theme/colors';

export const Header = styled.header`
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	background: ${colors.navbar_bg};
	height: 73px;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding: 0 2em;
`;

export const Link = styled.a`
	display: flex;
	align-items: center;
	color: black;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.1em;

	span {
		color: ${colors.blue_text};
		margin-left: 8px;
	}
`;

export const Logo = styled.img`
	width: 100px;
`;

export const NavWrapper = styled.nav``;

export const NavList = styled.ul`
	max-width: 900px;
	padding: 0 10px;
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	margin-bottom: 0;
`;

export const NavItem = styled.a`
	border-bottom: solid 2px transparent;
	color: ${colors.black_text};
	font-size: 16px;
	font-family: Montserrat;
	font-weight: 400;
	letter-spacing: 0.005em;
	white-space: nowrap;
	margin-right: 30px;

	:hover {
		color: ${colors.black_text};
		text-shadow: 0 0 1px black;
		/* border-bottom: solid 2px ${colors.blue_text}; */
	}

	:active {
		color: ${colors.black_text};
		text-shadow: 0 0 1px black;
		/* border-bottom: solid 2px ${colors.blue_text}; */
	}
`;

export const ButtonInputWrapper = styled.div`
	display: flex;
	width: 450px;
	justify-content: space-evenly;
	align-items: center;
`;

export const InputWrapper = styled.div`
	width: 300px;
	position: relative;
`;

export const Input = styled.input`
	width: 250px;
	background-color: #e3f1fc;
	border: 1px solid #b5cfe6;
	border-radius: 4px;
	padding: 8px 16px;
	margin-right: 10px;

	::placeholder {
		color: #729ec5;
		font-weight: 400;
		line-height: 150%;
	}
`;

export const SearchButton = styled.img`
	position: relative;
	top: 0px;
	right: 50px;
	width: 25px;
`;

export const Btn = styled.button`
	display: none; // not using now
	width: 100%;
	height: 42px;
	max-width: 83px;
	min-width: 83px;
	border-radius: 15px;
	border: none;
	background: ${colors.button_hover_bg};
	color: ${colors.white_text};
	font-weight: 700;
	margin-right: 10px;
	cursor: pointer;

	:hover {
		background-color: ${colors.button_bg};
		transition: 0.4s;
	}
`;

export const Profile = styled.img`
	width: 33px;
	cursor: pointer;
`;
