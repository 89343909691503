import styled from 'styled-components';

type WrapperProps = {
	width?: string;
	display?: string;
	ju?: string;
	position?: string;
	zIndex?: string;
};

export const Wrapper = styled.div<WrapperProps>`
	width: ${(props) => props.width || '100%'};
	display: ${(props) => props.display || 'flex'};
	justify-content: ${(props) => props.ju || 'center'};
	position: ${(props) => props.position};
	z-index: ${(props) => props.zIndex};
`;
