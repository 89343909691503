import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { createInjectorsEnhancer } from 'redux-injectors';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createRootReducer } from './reducer';
import { rootSaga } from './saga';

const rootReducer = createRootReducer();
function configureAppStore() {
	const sagaMiddleware = createSagaMiddleware();

	const { run: runSaga } = sagaMiddleware;
	const middlewares = [sagaMiddleware];

	const enhancers = [
		createInjectorsEnhancer({
			createReducer: createRootReducer,
			runSaga,
		}),
	];

	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => [
			...getDefaultMiddleware({ serializableCheck: false }),
			...middlewares,
		],
		devTools: true,
		enhancers,
	});

	sagaMiddleware.run(rootSaga);

	return store;
}

export const store = configureAppStore();
export type ApplicationState = ReturnType<typeof rootReducer>;
export type ApplicationStore = ReturnType<typeof configureAppStore>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<ApplicationState> =
	useSelector;
