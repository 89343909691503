import { ApplicationState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { AssetInfo, MarketIndex, TargetAsset } from './types';

export const selectMarketIndexState = (state: ApplicationState) =>
	state.marketIndex || initialState;

export const selectMarketIndexDomainData = createSelector(
	selectMarketIndexState,
	(marketIndexState) => marketIndexState.data
);

export const selectMarketIndexLoading = (state: ApplicationState) =>
	selectMarketIndexState(state).loading;

export const selectMarketIndexErrorMessage = (state: ApplicationState) =>
	selectMarketIndexState(state).errorMessage;

export const selectMarketCategoryList = createSelector(
	selectMarketIndexDomainData,
	(data) => {
		const categorySet = new Set<string>();
		data.forEach(({ category }) => {
			categorySet.add(category);
		});
		return [...categorySet];
	}
);

export const selectMarketDataByCategory = (target: string) => {
	return createSelector(selectMarketIndexDomainData, (data) =>
		data.filter(({ category }) => category === target)
	);
};

export const convertMarketIndexTableDataList = (data: Array<MarketIndex>) => {
	return data.map((one, idx) => ({
		key: idx + 1,
		assetInfo: one.assetInfo,
		category: one.category,
		index: [one.name, one.fullname],
		underlyingAssets: one.targetAssets,
		value: one.value,
		change24h: one.percentageChange['24h'],
		change7d: one.percentageChange['7d'],
		change30d: one.percentageChange['30d'],
		changeYtd: one.percentageChange.ytd,
		trend7d: one.trends['7d'],
	}));
};

export const selectMarketIndexTableData = createSelector(
	selectMarketIndexDomainData,
	convertMarketIndexTableDataList
);

export const selectMarketIndexTableDataByCategory = (
	state: ApplicationState,
	categoryName: string
) => {
	const categoryNameLowerCase = categoryName.toLocaleLowerCase();
	const filteredData = selectMarketIndexDomainData(state).filter(
		(one) => one.category.toLowerCase() === categoryNameLowerCase
	);
	return convertMarketIndexTableDataList(filteredData);
};

export interface MarketIndexTableRow {
	key: number | string;
	assetInfo: AssetInfo;
	category: string;
	index: string[];
	underlyingAssets: TargetAsset[];
	value: number;
	change24h: number;
	change7d: number;
	change30d: number;
	changeYtd: number;
	trend7d: string;
}

export const selectOneMarketIndexInfo = (
	state: ApplicationState,
	marketIndexSymbol: string
) => {
	return selectMarketIndexDomainData(state).find(
		(one) => one.name === marketIndexSymbol
	);
};

export const selectMarketIndexUnderlyingAssets = (
	state: ApplicationState,
	marketIndexSymbol: string
) => {
	return selectOneMarketIndexInfo(state, marketIndexSymbol)?.assetInfo;
};
