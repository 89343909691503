import styled, { css } from 'styled-components';

import colors from 'theme/colors';
import device from 'utils/devices';

interface NewsCardProps {
	section?: boolean;
	related?: boolean;
}

export const NewsCard = styled.div<NewsCardProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 600px;
	background: #ffffff;
	border-radius: 16px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
	margin-top: 30px;
	margin-right: 15px;
	${(props) =>
		props.section &&
		css`
			width: 330px;
		`}

	${(props) =>
		props.related &&
		css`
			width: 330px;
			height: 417px;
			margin-top: 30px;
			margin-right: 15px;

			@media ${device.tablet} {
				width: 300px;
				margin-top: 30px;
				margin-right: 10px;
			}

			@media ${device.mobile} {
				width: 300px;
				margin-top: 30px;
				margin-right: 0px;
			}
		`}
`;

export const NewsImage = styled.img`
	width: 100%;
	height: 200px;
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
`;

export const ArticalWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	border-bottom-right-radius: 16px;
	border-top-right-radius: 16px;
	padding: 15px;
`;

export const Sector = styled.span`
	color: ${colors.blue_text};
	line-height: 120%;
	font-weight: 600;
`;

export const HeadWrapper = styled.div`
	padding: 4px 0 8px;
	white-space: nowrap;
`;

export const Date = styled(Sector)`
	font-weight: 500;
	color: ${colors.dark_gray_text};
	padding-right: 5px;
`;

export const ReadTime = styled(Sector)`
	font-weight: 500;
	padding-left: 10px;
	color: ${colors.dark_gray_text};
	border-left: 2px solid #b5cfe6;
`;

export const Title = styled.strong`
	font-size: 16px;
	font-weight: 800;
	line-height: 120%;
	color: ${colors.black_text};
`;

export const Content = styled.p<NewsCardProps>`
	max-height: 180px;
	color: ${colors.dark_gray_text};
	font-size: 14px;
	padding: 20px 0;
	overflow: hidden;
	text-overflow: ellipsis;

	${(props) =>
		props.related &&
		css`
			display: none;
		`}
`;

export const TagWrapper = styled.div`
	position: absolute;
	bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
`;

export const Tag = styled.button`
	background: ${colors.tag_bg};
	border: 0.5px solid #b5cfe6;
	border-radius: 200px;
	font-size: 13px;
	margin-right: 10px;
	color: ${colors.dark_gray_text};
	font-weight: 600;
	margin-top: 10px;
	padding: 3px 20px;
	white-space: nowrap;

	:hover {
		background-color: ${colors.tag_hover_bg};
		transition: 0.4s;
	}
`;
