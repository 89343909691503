import * as Styled from './NewsCard.styled';

import React, { useState } from 'react';

import { ArticleResult } from 'page/ArticleDetailPage/types';
import MobileNewsCard from 'components/NewsCard/MobileNewsCard/MobileNewsCard';
import noarticle from 'assets/icons/noarticle.svg';

type NewsCardProps = {
	selectedCategoryNewsCards: ArticleResult[];
	subCategory?: string;
};

const DISPLAY_ARTICLE_OFFSET = 8;

const NewsCard = ({
	selectedCategoryNewsCards,
	subCategory,
}: NewsCardProps) => {
	const [numberOfArticle, setNumberOfArticle] = useState(
		DISPLAY_ARTICLE_OFFSET
	);

	const selectedSubCategoryCards = selectedCategoryNewsCards.filter(
		(cards) => cards.subcategory.name === subCategory
	);
	const showMoreArticle = () => {
		setNumberOfArticle(numberOfArticle + DISPLAY_ARTICLE_OFFSET);
	};

	return (
		<>
			<Styled.NewsCardWrapper>
				{selectedSubCategoryCards.length > 0 ? (
					selectedSubCategoryCards.slice(0, numberOfArticle).map((card) => {
						return (
							<Styled.SubNewsCardContainer key={card.id}>
								<MobileNewsCard section news={card} />
							</Styled.SubNewsCardContainer>
						);
					})
				) : (
					<Styled.NoArticleWrapper>
						<Styled.NoArticleContainer src={noarticle} alt="noarticle" />
					</Styled.NoArticleWrapper>
				)}
			</Styled.NewsCardWrapper>
			<Styled.ButtonWrapper>
				{selectedSubCategoryCards.length > 8 &&
				numberOfArticle < selectedSubCategoryCards.length ? (
					<Styled.Button onClick={showMoreArticle}>Load More</Styled.Button>
				) : null}
			</Styled.ButtonWrapper>
		</>
	);
};

export default NewsCard;
