import coinData from 'assets/top500CoinMarket.json';
import defaultLogo from 'assets/icons/default-token-logo.svg';

const COINMARKETCAP_URL = 'https://coinmarketcap.com/currencies/';
const COINMARKETCAP_ICON_URL =
	'https://s2.coinmarketcap.com/static/img/coins/64x64/';

type CoinmarketcapCurrency = {
	symbol: string;
	name: string;
	rank: number;
	id: number;
	slug: string;
	status: string;
	icon: string;
	url: string;
};

export const getCoinmarketcapCurrency = (
	symbol: string
): CoinmarketcapCurrency => {
	const symbolUpperCase = symbol.toUpperCase();
	const coin = coinData.find((item) => item.symbol === symbolUpperCase);

	if (coin) {
		return {
			...coin,
			icon: `${COINMARKETCAP_ICON_URL}${coin.id}.png`,
			url: `${COINMARKETCAP_URL}${coin.slug}`,
		};
	}

	return {
		symbol: symbolUpperCase,
		name: symbolUpperCase,
		rank: 0,
		id: 0,
		slug: '',
		status: '',
		icon: defaultLogo,
		url: '',
	};
};

export const getCurrencyIcon = (symbol: string) => {
	const { icon } = getCoinmarketcapCurrency(symbol);
	return icon;
};
