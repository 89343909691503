import colors from 'theme/colors';
import device from 'utils/devices';
import styled from 'styled-components';

export const NewsCardWrapper = styled.div`
	padding: 20px 0px 0px 0px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: start;

	@media ${device.tablet} {
		justify-content: center;
	}
`;

export const SubNewsCardContainer = styled.div`
	justify-content: center;
`;

export const NoArticleWrapper = styled.div`
	height: 50vh;
	width: 100%;
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NoArticleContainer = styled.img`
	width: 500px;

	@media ${device.tablet} {
		width: 350px;
	}

	@media ${device.mobile} {
		width: 300px;
	}
`;

export const Button = styled.button`
	background: ${colors.primary_bg};
	border: 2px solid ${colors.button_bg};
	color: ${colors.button_bg};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 28px;
	gap: 10px;
	width: 232px;
	height: 49px;
	border-radius: 200px;
	font-weight: 700;
	font-size: 16px;
	line-height: 150%;

	@media ${device.tablet} {
		width: 140px;
		height: 40px;
		font-size: 14px;
	}

	:hover {
		background: ${colors.button_bg};
		transition: 0.3s;
		color: ${colors.white_text};
	}

	:active {
		background: ${colors.button_hover_bg};
		transition: 0.3s;
	}
`;

export const ButtonWrapper = styled.div`
	margin-top: 50px;
	display: flex;
	justify-content: center;
`;
