import styled, { css } from 'styled-components';

import colors from 'theme/colors';
import device from 'utils/devices';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1440px;
	padding: 40px 30px;
`;

export const TopSection = styled.section`
	display: flex;
`;

export const TitleContainer = styled.div`
	width: 25%;
	padding: 30px 0;
`;

export const BackButton = styled.button`
	background-color: transparent;
	border: none;
`;

export const BackImagewrapper = styled.div`
	span {
		margin-left: 5px;
		color: rgba(0, 0, 0, 0.85);
	}

	@media ${device.tablet} {
		padding: 0px 0px 30px 0px;
	}
`;

export const Text = styled.div`
	color: ${colors.dark_gray_text_2};
	font-weight: 600;
	font-size: 14px;
	line-height: 175%;
	margin-top: 20px;
`;

export const Navigation = styled.div`
	padding-left: 20px;
`;

type LinkProps = {
	active?: boolean;
};

export const Link = styled.a<LinkProps>`
	a {
		color: ${colors.dark_gray_text_2};
		opacity: 0.45;
		position: relative;
		display: block;
		font-weight: 600;
		font-size: 14px;
		line-height: 175%;
		padding-top: 10px;
		cursor: pointer;

		${(props) =>
			props.active &&
			css`
				opacity: 1;
			`}
	}
`;

export const ArticleContainer = styled.section`
	justify-content: flex-end;
	background: ${colors.white_text};
	width: 75%;
	background: ${colors.white_text};
	border-radius: 16px;
	margin-left: 15px;
	padding: 32px;

	@media ${device.tablet} {
		width: 100%;
		padding: 32px 10px 32px 20px;
		margin-left: 0px;
	}
`;

export const ArticleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const SubCategoryText = styled.span`
	font-weight: 700;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	color: ${colors.blue_text};
`;

export const Title = styled.strong`
	width: 80%;
	color: ${colors.black_text};
	font-weight: 700;
	font-size: 30px;
	line-height: 120%;
	text-align: center;
	letter-spacing: -0.25px;
	padding-top: 20px;
`;

export const DetailTextWrapper = styled.span`
	display: flex;
	padding-top: 15px;
`;

type DetailTextProps = {
	left?: boolean;
};

export const DetailText = styled.span<DetailTextProps>`
	color: ${colors.dark_gray_text};
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	padding: 0px 10px;

	${(props) =>
		props.left &&
		css`
			border-left: 2px solid #b5cfe6;
		`}
`;

export const BannerImage = styled.div`
	width: 75%;
	padding-top: 24px;
`;

export const BannerText = styled.span`
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: #1f5882;
	padding-top: 16px;
`;

export const ContentWrapper = styled.div`
	padding: 24px 0px 0px 0px;
`;

export const ArticleFooterContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;
	padding-top: 10px;

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
	}
`;

export const TagsWrapper = styled.div`
	height: 100%;
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	align-items: center;
	span {
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
		color: ${colors.dark_gray_text};
		padding-right: 20px;
	}

	@media ${device.tablet} {
		margin-top: 30px;
	}
`;

export const SocialMediaLinksWrapper = styled.div`
	img {
		padding-right: 20px;
	}
`;

export const RelatedContentSection = styled.section``;

export const RelatedContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 32px 0px 0px 0px;
`;

export const RelatedContentText = styled.text`
	font-weight: 700;
	font-size: 30px;
	line-height: 120%;
	align-items: center;
	letter-spacing: -0.25px;
	color: ${colors.dark_gray_text};

	@media ${device.tablet} {
		padding: 20px 0px 0px 0px;
		text-align: center;
	}
`;

export const ExploreButtonWrapper = styled.div`
	display: flex;
	padding: 30px;
	justify-content: center;
`;

export const ExploreButton = styled.button`
	justify-content: center;
	align-items: center;
	padding: 5px 28px;
	width: 172px;
	height: 42px;
	font-weight: 700;
	border: 2px solid #005b9d;
	color: #005b9d;
	border-radius: 200px;
	background-color: ${colors.primary_bg};
	cursor: pointer;
`;

export const RelatedArticleWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	@media ${device.tablet} {
		justify-content: center;
	}
`;
export const NoArticleWrapper = styled.div`
	/* height: 50vh; */
	width: 100%;
	padding: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NoArticleContainer = styled.img`
	width: 250px;
`;
